import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { vehicleInputs } from '../../inputs/vehicle.inputs';

const form: FormInput[] = [
  vehicleInputs.type,
  vehicleInputs.photo,
  vehicleInputs.registerNumber,
  vehicleInputs.brand,
  vehicleInputs.model,
  vehicleInputs.name,
  vehicleInputs.VIN,
  vehicleInputs.mileage,
  vehicleInputs.commissioning,
  vehicleInputs.warranty,
  vehicleInputs.runningHours,
  vehicleInputs.modelYear,
  vehicleInputs.description,
];

export const vehicleCreateForm = createForm(form);

export type VehicleCreateFormBody = {
  name: string;
  type: string;
  registerNumber: string;
  VIN: string;
  mileage: number;
  commissioning: string;
  warranty: string;
  runningHours: number;
  brand: string;
  photo: null | File | string;
  model: string;
  modelYear: number;
  description: string;
};
