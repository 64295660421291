import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { feedbackInput } from '../inputs/feedback.input';

const form: FormInput[] = [feedbackInput.message];

export const feedbackForm = createForm(form);

export type FeedbackFormBody = {
  message: string;
};
