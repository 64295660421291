import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared-ui/lib/components/Button';
import { TableColumn } from 'shared-ui/lib/customs/react/generic-table/TableColumn';
import { TeamIndexSceneQuery } from '../generated/graphql';

const name: TableColumn = {
  field: 'name',
  text: 'User:name',
};

const email: TableColumn = {
  field: 'email',
  text: 'User:email',
};

const isEmployee: TableColumn = {
  field: 'isEmployee',
  text: 'User:isEmployee',
  formatter: (data: TeamIndexSceneQuery['users']['list'][0]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    if (data.isEmployee) return t('User:employee');
    return t('User:admin');
  },
};

const deleteAction = (onDelete: (opt: { variables: { id: string } }) => void): TableColumn => ({
  field: 'id',
  text: 'General:delete',
  formatter: (data: TeamIndexSceneQuery['users']['list'][0]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    if (data.isBusinessAccountOwner) return <span />;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleDelete = useCallback(
      (id: string) => {
        if (confirm(t('TeamIndexScene:deletePrompt'))) onDelete({ variables: { id } });
      },
      [t]
    );

    return (
      <Button type="button" onClick={() => handleDelete(data.id)}>
        {t('General:delete')}
      </Button>
    );
  },
});

export const userColumns = {
  name,
  email,
  isEmployee,
  deleteAction,
};
