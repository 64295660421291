import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { voucherInput } from '../inputs/voucher.input';

const form: FormInput[] = [voucherInput.voucher, voucherInput.isAccepted];

export const voucherForm = createForm(form);

export type VoucherFormBody = {
  voucher: string;
  isAccepted: boolean;
};
