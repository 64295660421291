import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AcceptInvitationDto = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type AdminBusinessType = {
  __typename?: 'AdminBusinessType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export type AdminOptionsDto = {
  archiveUser: Scalars['String']['input'];
  stripePriceId: Scalars['String']['input'];
};

export type AdminOptionsType = {
  __typename?: 'AdminOptionsType';
  archiveUser: Scalars['String']['output'];
  id: Scalars['String']['output'];
  stripePriceId: Scalars['String']['output'];
};

export type AdminPartnerType = {
  __typename?: 'AdminPartnerType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AdminUpdateUserDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AdminUpdateVehicleDto = {
  isArchived: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};

export type AdminUserType = {
  __typename?: 'AdminUserType';
  business?: Maybe<AdminBusinessType>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBusinessAccountOwner: Scalars['Boolean']['output'];
  isEmployee: Scalars['Boolean']['output'];
  isPartnerAccountOwner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner?: Maybe<AdminPartnerType>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AdminVehicleList = {
  __typename?: 'AdminVehicleList';
  list: Array<AdminVehicleType>;
  totalCount: Scalars['Int']['output'];
};

export type AdminVehicleSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type AdminVehicleType = {
  __typename?: 'AdminVehicleType';
  VIN?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  business?: Maybe<AdminBusinessType>;
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  model?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  user?: Maybe<AdminUserType>;
};

export type AdminVehicleWhere = {
  VIN?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String']['output'];
  user: UserType;
};

export type AuthStatusResponse = {
  __typename?: 'AuthStatusResponse';
  status: Scalars['String']['output'];
};

export type BusinessType = {
  __typename?: 'BusinessType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ChangeUserInformationDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CompleteReminderDto = {
  mileage?: InputMaybe<Scalars['Int']['input']>;
  reminderId: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceEvents: Array<UpdateServiceEventDto>;
  vehicleId: Scalars['String']['input'];
};

export type CompleteReminderResponse = {
  __typename?: 'CompleteReminderResponse';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['String']['output']>;
};

export type ConfirmDto = {
  confirmToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateFaultDto = {
  description: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  vehicleId: Scalars['String']['input'];
};

export type CreateReminderDto = {
  date: Scalars['DateTime']['input'];
  faultId?: InputMaybe<Scalars['String']['input']>;
  isNotificationable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDaysBefore?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateServiceEventDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceDate: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateVehicleDto = {
  VIN: Scalars['String']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  commissioning?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FaultList = {
  __typename?: 'FaultList';
  list: Array<FaultType>;
  totalCount: Scalars['Int']['output'];
};

export type FaultSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type FaultType = {
  __typename?: 'FaultType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDone: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  user: UserType;
  vehicle: VehicleType;
};

export type FaultWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FeedbackStatusType = {
  __typename?: 'FeedbackStatusType';
  status: Scalars['String']['output'];
};

export type ForgotPasswordDto = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  status: Scalars['String']['output'];
};

export type InviteBusinessUserDto = {
  email: Scalars['String']['input'];
  isEmployee: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type InviteUserDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LoginDto = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** [Admin] Delete user */
  aDeleteUser: AdminUserType;
  /** [Admin] Update user */
  aUpdateUser: AdminUserType;
  /** [Admin] Update vehicle */
  aUpdateVehicle: AdminVehicleType;
  /** Complete user invitation */
  acceptInvitationToken: Scalars['Boolean']['output'];
  /** Update user information */
  changeUserInformation: UserType;
  /** Complete reminder */
  completeReminder: CompleteReminderResponse;
  /** Confirm user email */
  confirm: AuthStatusResponse;
  /** Create a new fault */
  createFault: FaultType;
  createReminder: ReminderType;
  /** Create service event */
  createServiceEvent: ServiceEventType;
  /** Create a transfer token for the vehicle */
  createTransferToken: Scalars['String']['output'];
  /** Create a vehicle */
  createVehicle: VehicleType;
  /** Create a voucher */
  createVoucher: VoucherType;
  /** Delete the fault */
  deleteFault: FaultType;
  deleteReminder: ReminderType;
  /** Delete service event */
  deleteServiceEvent: ServiceEventType;
  /** Delete user */
  deleteUser: UserType;
  /** Delete a vehicle */
  deleteVehicle: VehicleType;
  /** Send forgot password link email to user */
  forgotPasswordConsumer: ForgotPasswordResponse;
  /** Send forgot password link email to user */
  forgotPasswordEmployee: ForgotPasswordResponse;
  /** Send forgot password link email to user */
  forgotPasswordPartner: ForgotPasswordResponse;
  /** Invite a user to the business */
  inviteBusinessUser: UserType;
  /** Invite partner user */
  invitePartnerUser: UserType;
  /** Login a consumer user */
  loginClient: AuthResponse;
  /** Login a business user */
  loginEmployee: AuthResponse;
  /** Login a partner user */
  loginPartner: AuthResponse;
  /** Mark the fault as read */
  markAsRead: Scalars['Boolean']['output'];
  /** Register a new business user account */
  registerBusinessUser: AuthResponse;
  /** Register a new consumer user account */
  registerConsumerUser: AuthResponse;
  /** Register a new partner user account */
  registerPartnerUser: AuthResponse;
  /** Account deletion request */
  requestAccountDelete: Scalars['Boolean']['output'];
  /** Resend confirmation email */
  resendConfirmation: AuthStatusResponse;
  /** Reset password with token */
  resetPassword: ResetPasswordResponse;
  /** Send feedback to the team */
  sendFeedback: FeedbackStatusType;
  /** Transfer the vehicle to the user */
  transferVehicle: TransferVehicleType;
  /** [Admin] Transfer vehicle to business account */
  transferVehicleToBusiness: AdminVehicleType;
  /** [Admin] Transfer vehicle to user account */
  transferVehicleToConsumer: AdminVehicleType;
  /** Update admin options */
  updateAdminOptions: AdminOptionsType;
  /** Update the business details of the current user */
  updateBusiness: BusinessType;
  /** Update the fault details */
  updateFault: FaultType;
  /** Mark the fault as done */
  updateFaultAsDone: FaultType;
  /** Update partner details by user */
  updatePartner: PartnerType;
  updateReminder: ReminderType;
  /** Update service event */
  updateServiceEvent: ServiceEventType;
  /** Update a vehicle */
  updateVehicle: VehicleType;
  /** Update a voucher */
  updateVoucher: VoucherType;
  /** Use a voucher */
  useVoucher: VoucherLogType;
  /** Verify if the invitation token is valid */
  verifyAcceptInvitationToken: Scalars['Boolean']['output'];
};


export type MutationADeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationAUpdateUserArgs = {
  body: AdminUpdateUserDto;
  id: Scalars['String']['input'];
};


export type MutationAUpdateVehicleArgs = {
  body: AdminUpdateVehicleDto;
  id: Scalars['String']['input'];
};


export type MutationAcceptInvitationTokenArgs = {
  body: AcceptInvitationDto;
};


export type MutationChangeUserInformationArgs = {
  body: ChangeUserInformationDto;
};


export type MutationCompleteReminderArgs = {
  body: CompleteReminderDto;
};


export type MutationConfirmArgs = {
  body: ConfirmDto;
};


export type MutationCreateFaultArgs = {
  body: CreateFaultDto;
};


export type MutationCreateReminderArgs = {
  body: CreateReminderDto;
};


export type MutationCreateServiceEventArgs = {
  body: CreateServiceEventDto;
};


export type MutationCreateTransferTokenArgs = {
  vehicleId: Scalars['String']['input'];
};


export type MutationCreateVehicleArgs = {
  body: CreateVehicleDto;
};


export type MutationCreateVoucherArgs = {
  body: VoucherDto;
};


export type MutationDeleteFaultArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteReminderArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteServiceEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVehicleArgs = {
  id: Scalars['String']['input'];
};


export type MutationForgotPasswordConsumerArgs = {
  body: ForgotPasswordDto;
};


export type MutationForgotPasswordEmployeeArgs = {
  body: ForgotPasswordDto;
};


export type MutationForgotPasswordPartnerArgs = {
  body: ForgotPasswordDto;
};


export type MutationInviteBusinessUserArgs = {
  body: InviteBusinessUserDto;
};


export type MutationInvitePartnerUserArgs = {
  body: InviteUserDto;
};


export type MutationLoginClientArgs = {
  body: LoginDto;
};


export type MutationLoginEmployeeArgs = {
  body: LoginDto;
};


export type MutationLoginPartnerArgs = {
  body: LoginDto;
};


export type MutationMarkAsReadArgs = {
  id: Scalars['String']['input'];
};


export type MutationRegisterBusinessUserArgs = {
  body: RegisterBusinessUserDto;
};


export type MutationRegisterConsumerUserArgs = {
  body: RegisterConsumerUserDto;
};


export type MutationRegisterPartnerUserArgs = {
  body: RegisterPartnerUserDto;
};


export type MutationResetPasswordArgs = {
  body: ResetPasswordDto;
};


export type MutationSendFeedbackArgs = {
  message: Scalars['String']['input'];
};


export type MutationTransferVehicleArgs = {
  token: Scalars['String']['input'];
};


export type MutationTransferVehicleToBusinessArgs = {
  businessId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationTransferVehicleToConsumerArgs = {
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUpdateAdminOptionsArgs = {
  body: AdminOptionsDto;
};


export type MutationUpdateBusinessArgs = {
  body: UpdateBusinessDto;
};


export type MutationUpdateFaultArgs = {
  body: UpdateFaultDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateFaultAsDoneArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdatePartnerArgs = {
  body: UpdatePartnerDto;
};


export type MutationUpdateReminderArgs = {
  body: UpdateReminderDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateServiceEventArgs = {
  body: UpdateServiceEventDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateVehicleArgs = {
  body: UpdateVehicleDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateVoucherArgs = {
  body: VoucherDto;
  id: Scalars['String']['input'];
};


export type MutationUseVoucherArgs = {
  vehicleId: Scalars['String']['input'];
  voucher: Scalars['String']['input'];
};


export type MutationVerifyAcceptInvitationTokenArgs = {
  body: VerifyAcceptInvitationDto;
};

export type PartnerType = {
  __typename?: 'PartnerType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** [Admin] Get business */
  aBusiness: AdminBusinessType;
  /** [Admin] Get user */
  aUser: AdminUserType;
  /** [Admin] Get users */
  aUsers: UserList;
  /** [Admin] Get a vehicle */
  aVehicle: AdminVehicleType;
  /** [Admin] Get vehicles */
  aVehicles: AdminVehicleList;
  /** Get admin options */
  adminOptions: AdminOptionsType;
  /** Get the business details of the current user */
  business: BusinessType;
  /** Create a new checkout session */
  createCheckoutSession: StripeCheckoutType;
  /** [Admin] Export users as CSV */
  exportCSV: Scalars['String']['output'];
  /** Get the fault details */
  fault: FaultType;
  /** Get the list of faults */
  faults: FaultList;
  /** Get partner details by user */
  partner: PartnerType;
  reminder: ReminderType;
  reminders: ReminderList;
  /** [Admin] Request admin login token */
  requestAdminToken: AuthStatusResponse;
  /** Search a vehicle by token */
  searchVehicle: VehicleType;
  /** Get service event */
  serviceEvent: ServiceEventType;
  /** Get all users */
  users: UserList;
  /** [Admin] Validate admin token */
  validateAdminToken: AuthStatusResponse;
  /** Validate reset password token */
  validateResetToken: ValidateResetTokenResponse;
  /** Validate the transfer token */
  validateTransferToken: ValidateTransferTokenType;
  /** Get a vehicle */
  vehicle: VehicleType;
  /** Get a list of vehicles */
  vehicles: VehicleList;
  /** Get a voucher */
  voucher: VoucherType;
  /** Get a list of vouchers */
  vouchers: VoucherList;
  /** Get user information */
  whoAmI: UserType;
};


export type QueryABusinessArgs = {
  id: Scalars['String']['input'];
};


export type QueryAUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryAUsersArgs = {
  orderBy?: InputMaybe<Array<UserSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhere>;
};


export type QueryAVehicleArgs = {
  id: Scalars['String']['input'];
};


export type QueryAVehiclesArgs = {
  orderBy?: InputMaybe<Array<AdminVehicleSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminVehicleWhere>;
};


export type QueryCreateCheckoutSessionArgs = {
  vehicleId: Scalars['String']['input'];
};


export type QueryFaultArgs = {
  id: Scalars['String']['input'];
};


export type QueryFaultsArgs = {
  orderBy?: InputMaybe<Array<FaultSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaultWhere>;
};


export type QueryReminderArgs = {
  id: Scalars['String']['input'];
};


export type QueryRemindersArgs = {
  orderBy?: InputMaybe<Array<ReminderSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
};


export type QuerySearchVehicleArgs = {
  token: Scalars['String']['input'];
};


export type QueryServiceEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryValidateResetTokenArgs = {
  body: ValidateResetTokenDto;
};


export type QueryValidateTransferTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryVehicleArgs = {
  id: Scalars['String']['input'];
};


export type QueryVehiclesArgs = {
  orderBy?: InputMaybe<Array<VehicleSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleWhere>;
};


export type QueryVoucherArgs = {
  id: Scalars['String']['input'];
};


export type QueryVouchersArgs = {
  orderBy?: InputMaybe<Array<VoucherSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VoucherWhere>;
};

export type RegisterBusinessUserDto = {
  businessName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterConsumerUserDto = {
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterPartnerUserDto = {
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  partnerName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ReminderList = {
  __typename?: 'ReminderList';
  list: Array<ReminderType>;
  totalCount: Scalars['Int']['output'];
};

export type ReminderSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type ReminderType = {
  __typename?: 'ReminderType';
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isNotificationable?: Maybe<Scalars['Boolean']['output']>;
  isPartnerLocked: Scalars['Boolean']['output'];
  notificationDaysBefore?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicle: VehicleType;
  vehicleId: Scalars['String']['output'];
};

export type ReminderWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ResetPasswordDto = {
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  status: Scalars['String']['output'];
};

export type ServiceEventSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  serviceDate?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceEventType = {
  __typename?: 'ServiceEventType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isLocked: Scalars['Boolean']['output'];
  isPartnerLocked: Scalars['Boolean']['output'];
  mileage?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  partner?: Maybe<PartnerType>;
  partnerId: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  runningHours?: Maybe<Scalars['Int']['output']>;
  serviceDate: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicle: VehicleType;
  vehicleId: Scalars['String']['output'];
};

export type ServiceEventWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StripeCheckoutType = {
  __typename?: 'StripeCheckoutType';
  url: Scalars['String']['output'];
};

export type TransferVehicleType = {
  __typename?: 'TransferVehicleType';
  vehicleId: Scalars['String']['output'];
};

export type UpdateBusinessDto = {
  name: Scalars['String']['input'];
};

export type UpdateFaultDto = {
  description: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerDto = {
  name: Scalars['String']['input'];
};

export type UpdateReminderDto = {
  date: Scalars['DateTime']['input'];
  isNotificationable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDaysBefore?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateServiceEventDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceDate: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
};

export type UpdateVehicleDto = {
  VIN: Scalars['String']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  commissioning?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserList = {
  __typename?: 'UserList';
  list: Array<AdminUserType>;
  totalCount: Scalars['Int']['output'];
};

export type UserSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UserType = {
  __typename?: 'UserType';
  business?: Maybe<BusinessType>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBusinessAccountOwner: Scalars['Boolean']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  isEmployee: Scalars['Boolean']['output'];
  isPartnerAccountOwner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner?: Maybe<PartnerType>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserWhere = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ValidateResetTokenDto = {
  resetPasswordToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ValidateResetTokenResponse = {
  __typename?: 'ValidateResetTokenResponse';
  status: Scalars['Boolean']['output'];
};

export type ValidateTransferTokenType = {
  __typename?: 'ValidateTransferTokenType';
  isVehicleOwner: Scalars['Boolean']['output'];
};

export type VehicleList = {
  __typename?: 'VehicleList';
  list: Array<VehicleType>;
  totalCount: Scalars['Int']['output'];
};

export type VehicleSort = {
  VIN?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  VIN: Scalars['String']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  commissioning?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hasActiveLicense: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  licenseExpireDate: Scalars['DateTime']['output'];
  licenseStatus: Scalars['String']['output'];
  mileage?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  reminders: Array<ReminderType>;
  runningHours?: Maybe<Scalars['Int']['output']>;
  serviceEvents: Array<ServiceEventType>;
  token: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  warranty?: Maybe<Scalars['DateTime']['output']>;
};


export type VehicleTypeRemindersArgs = {
  orderBy?: InputMaybe<Array<ReminderSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
};


export type VehicleTypeServiceEventsArgs = {
  orderBy?: InputMaybe<Array<ServiceEventSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceEventWhere>;
};

export type VehicleWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VerifyAcceptInvitationDto = {
  inviteToken: Scalars['String']['input'];
};

export type VoucherDto = {
  endDate: Scalars['DateTime']['input'];
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  useLimit: Scalars['Int']['input'];
};

export type VoucherList = {
  __typename?: 'VoucherList';
  list: Array<VoucherType>;
  totalCount: Scalars['Int']['output'];
};

export type VoucherLogType = {
  __typename?: 'VoucherLogType';
  id: Scalars['String']['output'];
};

export type VoucherSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type VoucherType = {
  __typename?: 'VoucherType';
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useLimit: Scalars['Int']['output'];
  uses: Scalars['Int']['output'];
};

export type VoucherWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountDeleteRequestMutationVariables = Exact<{ [key: string]: never; }>;


export type AccountDeleteRequestMutation = { __typename?: 'Mutation', requestAccountDelete: boolean };

export type CreateCheckoutSessionQueryVariables = Exact<{
  vehicleId: Scalars['String']['input'];
}>;


export type CreateCheckoutSessionQuery = { __typename?: 'Query', createCheckoutSession: { __typename?: 'StripeCheckoutType', url: string } };

export type CreateFaultMutationVariables = Exact<{
  body: CreateFaultDto;
}>;


export type CreateFaultMutation = { __typename?: 'Mutation', createFault: { __typename?: 'FaultType', id: string } };

export type FaultShowSceneQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FaultShowSceneQuery = { __typename?: 'Query', fault: { __typename?: 'FaultType', id: string, createdAt: any, description: string, photo?: string | null, isRead: boolean, user: { __typename?: 'UserType', name: string }, vehicle: { __typename?: 'VehicleType', id: string, registerNumber?: string | null, VIN: string } } };

export type FindVehicleQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<VehicleSort> | VehicleSort>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleWhere>;
}>;


export type FindVehicleQuery = { __typename?: 'Query', vehicles: { __typename?: 'VehicleList', list: Array<{ __typename?: 'VehicleType', id: string, registerNumber?: string | null, VIN: string }> } };

export type ResendConfirmMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendConfirmMutation = { __typename?: 'Mutation', resendConfirmation: { __typename?: 'AuthStatusResponse', status: string } };

export type SendFeedbackMutationVariables = Exact<{
  message: Scalars['String']['input'];
}>;


export type SendFeedbackMutation = { __typename?: 'Mutation', sendFeedback: { __typename?: 'FeedbackStatusType', status: string } };

export type StripeSceneQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type StripeSceneQuery = { __typename?: 'Query', vehicle: { __typename?: 'VehicleType', id: string, licenseExpireDate: any } };

export type UpdateFaultAsDoneMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type UpdateFaultAsDoneMutation = { __typename?: 'Mutation', updateFaultAsDone: { __typename?: 'FaultType', id: string } };

export type UseVoucherMutationVariables = Exact<{
  vehicleId: Scalars['String']['input'];
  voucher: Scalars['String']['input'];
}>;


export type UseVoucherMutation = { __typename?: 'Mutation', useVoucher: { __typename?: 'VoucherLogType', id: string } };

export type VehicleTextOnlyQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type VehicleTextOnlyQuery = { __typename?: 'Query', vehicle: { __typename?: 'VehicleType', VIN: string, brand?: string | null, commissioning?: any | null, mileage?: number | null, model?: string | null, modelYear?: number | null, registerNumber?: string | null, runningHours?: number | null, type?: string | null, warranty?: any | null, serviceEvents: Array<{ __typename?: 'ServiceEventType', id: string, type: string, name: string, description?: string | null, serviceDate: any, mileage?: number | null, runningHours?: number | null, partner?: { __typename?: 'PartnerType', name: string } | null }> } };

export type CreateTransferTokenMutationVariables = Exact<{
  vehicleId: Scalars['String']['input'];
}>;


export type CreateTransferTokenMutation = { __typename?: 'Mutation', createTransferToken: string };

export type ValidateTransferTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ValidateTransferTokenQuery = { __typename?: 'Query', validateTransferToken: { __typename?: 'ValidateTransferTokenType', isVehicleOwner: boolean } };

export type TransferVehicleMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type TransferVehicleMutation = { __typename?: 'Mutation', transferVehicle: { __typename?: 'TransferVehicleType', vehicleId: string } };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'UserType', id: string, email: string, name: string, type: string, isBusinessAccountOwner: boolean, isConfirmed: boolean, business?: { __typename?: 'BusinessType', id: string, name: string, photo?: string | null } | null } };

export type HomeSceneQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeSceneQuery = { __typename?: 'Query', vehicles: { __typename?: 'VehicleList', list: Array<{ __typename?: 'VehicleType', id: string, name?: string | null, registerNumber?: string | null, VIN: string, brand?: string | null, model?: string | null, photo?: string | null, mileage?: number | null, runningHours?: number | null, licenseExpireDate: any, licenseStatus: string }> }, reminders: { __typename?: 'ReminderList', totalCount: number, list: Array<{ __typename?: 'ReminderType', id: string, title: string, date: any, vehicleId: string, vehicle: { __typename?: 'VehicleType', id: string, name?: string | null, registerNumber?: string | null, VIN: string, brand?: string | null, model?: string | null } }> } };

export type VerifyAcceptInvitationTokenMutationVariables = Exact<{
  body: VerifyAcceptInvitationDto;
}>;


export type VerifyAcceptInvitationTokenMutation = { __typename?: 'Mutation', verifyAcceptInvitationToken: boolean };

export type AcceptInvitationMutationVariables = Exact<{
  body: AcceptInvitationDto;
}>;


export type AcceptInvitationMutation = { __typename?: 'Mutation', acceptInvitationToken: boolean };

export type ConfirmMutationVariables = Exact<{
  body: ConfirmDto;
}>;


export type ConfirmMutation = { __typename?: 'Mutation', confirm: { __typename?: 'AuthStatusResponse', status: string } };

export type ForgotPasswordMutationVariables = Exact<{
  body: ForgotPasswordDto;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPasswordConsumer: { __typename?: 'ForgotPasswordResponse', status: string } };

export type LoginMutationVariables = Exact<{
  body: LoginDto;
}>;


export type LoginMutation = { __typename?: 'Mutation', loginClient: { __typename?: 'AuthResponse', token: string, user: { __typename?: 'UserType', id: string, name: string } } };

export type RegisterConsumerMutationVariables = Exact<{
  body: RegisterConsumerUserDto;
}>;


export type RegisterConsumerMutation = { __typename?: 'Mutation', registerConsumerUser: { __typename?: 'AuthResponse', token: string } };

export type RegisterBusinessMutationVariables = Exact<{
  body: RegisterBusinessUserDto;
}>;


export type RegisterBusinessMutation = { __typename?: 'Mutation', registerBusinessUser: { __typename?: 'AuthResponse', token: string } };

export type ResetPasswordMutationVariables = Exact<{
  body: ResetPasswordDto;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'ResetPasswordResponse', status: string } };

export type ValidateResetTokenQueryVariables = Exact<{
  body: ValidateResetTokenDto;
}>;


export type ValidateResetTokenQuery = { __typename?: 'Query', validateResetToken: { __typename?: 'ValidateResetTokenResponse', status: boolean } };

export type FaultIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<FaultSort> | FaultSort>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaultWhere>;
}>;


export type FaultIndexSceneQuery = { __typename?: 'Query', faults: { __typename?: 'FaultList', totalCount: number, list: Array<{ __typename?: 'FaultType', id: string, createdAt: any, isRead: boolean, vehicle: { __typename?: 'VehicleType', registerNumber?: string | null, VIN: string, name?: string | null, brand?: string | null, model?: string | null }, user: { __typename?: 'UserType', name: string } }> } };

export type MarkAsReadMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type MarkAsReadMutation = { __typename?: 'Mutation', markAsRead: boolean };

export type CreateReminderMutationVariables = Exact<{
  body: CreateReminderDto;
}>;


export type CreateReminderMutation = { __typename?: 'Mutation', createReminder: { __typename?: 'ReminderType', id: string } };

export type UpdateReminderMutationVariables = Exact<{
  id: Scalars['String']['input'];
  body: UpdateReminderDto;
}>;


export type UpdateReminderMutation = { __typename?: 'Mutation', updateReminder: { __typename?: 'ReminderType', id: string } };

export type DeleteReminderMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteReminderMutation = { __typename?: 'Mutation', deleteReminder: { __typename?: 'ReminderType', id: string } };

export type ReminderQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReminderQuery = { __typename?: 'Query', reminder: { __typename?: 'ReminderType', title: string, date: any, notificationDaysBefore?: number | null, isNotificationable?: boolean | null } };

export type RemindersQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<ReminderSort> | ReminderSort>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
}>;


export type RemindersQuery = { __typename?: 'Query', reminders: { __typename?: 'ReminderList', totalCount: number, list: Array<{ __typename?: 'ReminderType', id: string, title: string, date: any, vehicleId: string, vehicle: { __typename?: 'VehicleType', id: string, name?: string | null, registerNumber?: string | null, VIN: string, brand?: string | null, model?: string | null } }> } };

export type CompleteReminderMutationVariables = Exact<{
  body: CompleteReminderDto;
}>;


export type CompleteReminderMutation = { __typename?: 'Mutation', completeReminder: { __typename?: 'CompleteReminderResponse', count: number, ids: Array<string> } };

export type CreateServiceEventMutationVariables = Exact<{
  body: CreateServiceEventDto;
}>;


export type CreateServiceEventMutation = { __typename?: 'Mutation', createServiceEvent: { __typename?: 'ServiceEventType', id: string } };

export type UpdateServiceEventMutationVariables = Exact<{
  id: Scalars['String']['input'];
  body: UpdateServiceEventDto;
}>;


export type UpdateServiceEventMutation = { __typename?: 'Mutation', updateServiceEvent: { __typename?: 'ServiceEventType', id: string } };

export type EditServiceEventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EditServiceEventQuery = { __typename?: 'Query', serviceEvent: { __typename?: 'ServiceEventType', type: string, name: string, photo?: string | null, serviceDate: any, mileage?: number | null, runningHours?: number | null, description?: string | null } };

export type ServiceEventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ServiceEventQuery = { __typename?: 'Query', serviceEvent: { __typename?: 'ServiceEventType', type: string, name: string, serviceDate: any, mileage?: number | null, photo?: string | null, runningHours?: number | null, description?: string | null, partner?: { __typename?: 'PartnerType', name: string, photo?: string | null } | null } };

export type UpdateBusinessMutationVariables = Exact<{
  body: UpdateBusinessDto;
}>;


export type UpdateBusinessMutation = { __typename?: 'Mutation', updateBusiness: { __typename?: 'BusinessType', id: string } };

export type BusinessQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessQuery = { __typename?: 'Query', business: { __typename?: 'BusinessType', name: string, photo?: string | null } };

export type ChangeUserInformationMutationVariables = Exact<{
  body: ChangeUserInformationDto;
}>;


export type ChangeUserInformationMutation = { __typename?: 'Mutation', changeUserInformation: { __typename?: 'UserType', id: string } };

export type UserEditWhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type UserEditWhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'UserType', email: string, name: string } };

export type TeamCreateSceneMutationVariables = Exact<{
  body: InviteBusinessUserDto;
}>;


export type TeamCreateSceneMutation = { __typename?: 'Mutation', inviteBusinessUser: { __typename?: 'UserType', id: string } };

export type TeamIndexSceneQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamIndexSceneQuery = { __typename?: 'Query', users: { __typename?: 'UserList', totalCount: number, list: Array<{ __typename?: 'AdminUserType', id: string, name: string, email: string, isBusinessAccountOwner: boolean, isEmployee: boolean }> } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'UserType', id: string } };

export type CreateVehicleMutationVariables = Exact<{
  body: CreateVehicleDto;
}>;


export type CreateVehicleMutation = { __typename?: 'Mutation', createVehicle: { __typename?: 'VehicleType', id: string } };

export type UpdateVehicleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  body: UpdateVehicleDto;
}>;


export type UpdateVehicleMutation = { __typename?: 'Mutation', updateVehicle: { __typename?: 'VehicleType', id: string } };

export type DeleteVehicleMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteVehicleMutation = { __typename?: 'Mutation', deleteVehicle: { __typename?: 'VehicleType', id: string } };

export type VehicleQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type VehicleQuery = { __typename?: 'Query', vehicle: { __typename?: 'VehicleType', name?: string | null, type?: string | null, registerNumber?: string | null, VIN: string, mileage?: number | null, modelYear?: number | null, commissioning?: any | null, warranty?: any | null, runningHours?: number | null, model?: string | null, photo?: string | null, brand?: string | null, description?: string | null } };

export type VehicleIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<VehicleSort> | VehicleSort>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleWhere>;
}>;


export type VehicleIndexSceneQuery = { __typename?: 'Query', vehicles: { __typename?: 'VehicleList', totalCount: number, list: Array<{ __typename?: 'VehicleType', id: string, name?: string | null, registerNumber?: string | null, model?: string | null, brand?: string | null, licenseExpireDate: any, licenseStatus: string, VIN: string }> } };

export type VehicleShowQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type VehicleShowQuery = { __typename?: 'Query', vehicle: { __typename?: 'VehicleType', id: string, token: string, name?: string | null, type?: string | null, registerNumber?: string | null, VIN: string, mileage?: number | null, modelYear?: number | null, commissioning?: any | null, warranty?: any | null, runningHours?: number | null, hasActiveLicense: boolean, model?: string | null, licenseExpireDate: any, licenseStatus: string, brand?: string | null, photo?: string | null, description?: string | null, reminders: Array<{ __typename?: 'ReminderType', id: string, title: string, date: any }>, serviceEvents: Array<{ __typename?: 'ServiceEventType', id: string, name: string, serviceDate: any, type: string, description?: string | null, isLocked: boolean, partner?: { __typename?: 'PartnerType', photo?: string | null } | null }> } };


export const AccountDeleteRequestDocument = gql`
    mutation AccountDeleteRequest {
  requestAccountDelete
}
    `;
export type AccountDeleteRequestMutationFn = Apollo.MutationFunction<AccountDeleteRequestMutation, AccountDeleteRequestMutationVariables>;

/**
 * __useAccountDeleteRequestMutation__
 *
 * To run a mutation, you first call `useAccountDeleteRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountDeleteRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountDeleteRequestMutation, { data, loading, error }] = useAccountDeleteRequestMutation({
 *   variables: {
 *   },
 * });
 */
export function useAccountDeleteRequestMutation(baseOptions?: Apollo.MutationHookOptions<AccountDeleteRequestMutation, AccountDeleteRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountDeleteRequestMutation, AccountDeleteRequestMutationVariables>(AccountDeleteRequestDocument, options);
      }
export type AccountDeleteRequestMutationHookResult = ReturnType<typeof useAccountDeleteRequestMutation>;
export type AccountDeleteRequestMutationResult = Apollo.MutationResult<AccountDeleteRequestMutation>;
export type AccountDeleteRequestMutationOptions = Apollo.BaseMutationOptions<AccountDeleteRequestMutation, AccountDeleteRequestMutationVariables>;
export const CreateCheckoutSessionDocument = gql`
    query CreateCheckoutSession($vehicleId: String!) {
  createCheckoutSession(vehicleId: $vehicleId) {
    url
  }
}
    `;

/**
 * __useCreateCheckoutSessionQuery__
 *
 * To run a query within a React component, call `useCreateCheckoutSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateCheckoutSessionQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useCreateCheckoutSessionQuery(baseOptions: Apollo.QueryHookOptions<CreateCheckoutSessionQuery, CreateCheckoutSessionQueryVariables> & ({ variables: CreateCheckoutSessionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateCheckoutSessionQuery, CreateCheckoutSessionQueryVariables>(CreateCheckoutSessionDocument, options);
      }
export function useCreateCheckoutSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateCheckoutSessionQuery, CreateCheckoutSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateCheckoutSessionQuery, CreateCheckoutSessionQueryVariables>(CreateCheckoutSessionDocument, options);
        }
export function useCreateCheckoutSessionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreateCheckoutSessionQuery, CreateCheckoutSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateCheckoutSessionQuery, CreateCheckoutSessionQueryVariables>(CreateCheckoutSessionDocument, options);
        }
export type CreateCheckoutSessionQueryHookResult = ReturnType<typeof useCreateCheckoutSessionQuery>;
export type CreateCheckoutSessionLazyQueryHookResult = ReturnType<typeof useCreateCheckoutSessionLazyQuery>;
export type CreateCheckoutSessionSuspenseQueryHookResult = ReturnType<typeof useCreateCheckoutSessionSuspenseQuery>;
export type CreateCheckoutSessionQueryResult = Apollo.QueryResult<CreateCheckoutSessionQuery, CreateCheckoutSessionQueryVariables>;
export const CreateFaultDocument = gql`
    mutation CreateFault($body: CreateFaultDTO!) {
  createFault(body: $body) {
    id
  }
}
    `;
export type CreateFaultMutationFn = Apollo.MutationFunction<CreateFaultMutation, CreateFaultMutationVariables>;

/**
 * __useCreateFaultMutation__
 *
 * To run a mutation, you first call `useCreateFaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaultMutation, { data, loading, error }] = useCreateFaultMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateFaultMutation(baseOptions?: Apollo.MutationHookOptions<CreateFaultMutation, CreateFaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFaultMutation, CreateFaultMutationVariables>(CreateFaultDocument, options);
      }
export type CreateFaultMutationHookResult = ReturnType<typeof useCreateFaultMutation>;
export type CreateFaultMutationResult = Apollo.MutationResult<CreateFaultMutation>;
export type CreateFaultMutationOptions = Apollo.BaseMutationOptions<CreateFaultMutation, CreateFaultMutationVariables>;
export const FaultShowSceneDocument = gql`
    query FaultShowScene($id: String!) {
  fault(id: $id) {
    id
    createdAt
    description
    photo
    isRead
    user {
      name
    }
    vehicle {
      id
      registerNumber
      VIN
    }
  }
}
    `;

/**
 * __useFaultShowSceneQuery__
 *
 * To run a query within a React component, call `useFaultShowSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaultShowSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaultShowSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFaultShowSceneQuery(baseOptions: Apollo.QueryHookOptions<FaultShowSceneQuery, FaultShowSceneQueryVariables> & ({ variables: FaultShowSceneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaultShowSceneQuery, FaultShowSceneQueryVariables>(FaultShowSceneDocument, options);
      }
export function useFaultShowSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaultShowSceneQuery, FaultShowSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaultShowSceneQuery, FaultShowSceneQueryVariables>(FaultShowSceneDocument, options);
        }
export function useFaultShowSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FaultShowSceneQuery, FaultShowSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FaultShowSceneQuery, FaultShowSceneQueryVariables>(FaultShowSceneDocument, options);
        }
export type FaultShowSceneQueryHookResult = ReturnType<typeof useFaultShowSceneQuery>;
export type FaultShowSceneLazyQueryHookResult = ReturnType<typeof useFaultShowSceneLazyQuery>;
export type FaultShowSceneSuspenseQueryHookResult = ReturnType<typeof useFaultShowSceneSuspenseQuery>;
export type FaultShowSceneQueryResult = Apollo.QueryResult<FaultShowSceneQuery, FaultShowSceneQueryVariables>;
export const FindVehicleDocument = gql`
    query FindVehicle($orderBy: [VehicleSort!], $skip: Int, $take: Int, $where: VehicleWhere) {
  vehicles(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      registerNumber
      VIN
    }
  }
}
    `;

/**
 * __useFindVehicleQuery__
 *
 * To run a query within a React component, call `useFindVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindVehicleQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindVehicleQuery(baseOptions?: Apollo.QueryHookOptions<FindVehicleQuery, FindVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindVehicleQuery, FindVehicleQueryVariables>(FindVehicleDocument, options);
      }
export function useFindVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindVehicleQuery, FindVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindVehicleQuery, FindVehicleQueryVariables>(FindVehicleDocument, options);
        }
export function useFindVehicleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindVehicleQuery, FindVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindVehicleQuery, FindVehicleQueryVariables>(FindVehicleDocument, options);
        }
export type FindVehicleQueryHookResult = ReturnType<typeof useFindVehicleQuery>;
export type FindVehicleLazyQueryHookResult = ReturnType<typeof useFindVehicleLazyQuery>;
export type FindVehicleSuspenseQueryHookResult = ReturnType<typeof useFindVehicleSuspenseQuery>;
export type FindVehicleQueryResult = Apollo.QueryResult<FindVehicleQuery, FindVehicleQueryVariables>;
export const ResendConfirmDocument = gql`
    mutation ResendConfirm {
  resendConfirmation {
    status
  }
}
    `;
export type ResendConfirmMutationFn = Apollo.MutationFunction<ResendConfirmMutation, ResendConfirmMutationVariables>;

/**
 * __useResendConfirmMutation__
 *
 * To run a mutation, you first call `useResendConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmMutation, { data, loading, error }] = useResendConfirmMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmMutation, ResendConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmMutation, ResendConfirmMutationVariables>(ResendConfirmDocument, options);
      }
export type ResendConfirmMutationHookResult = ReturnType<typeof useResendConfirmMutation>;
export type ResendConfirmMutationResult = Apollo.MutationResult<ResendConfirmMutation>;
export type ResendConfirmMutationOptions = Apollo.BaseMutationOptions<ResendConfirmMutation, ResendConfirmMutationVariables>;
export const SendFeedbackDocument = gql`
    mutation SendFeedback($message: String!) {
  sendFeedback(message: $message) {
    status
  }
}
    `;
export type SendFeedbackMutationFn = Apollo.MutationFunction<SendFeedbackMutation, SendFeedbackMutationVariables>;

/**
 * __useSendFeedbackMutation__
 *
 * To run a mutation, you first call `useSendFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackMutation, { data, loading, error }] = useSendFeedbackMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SendFeedbackMutation, SendFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFeedbackMutation, SendFeedbackMutationVariables>(SendFeedbackDocument, options);
      }
export type SendFeedbackMutationHookResult = ReturnType<typeof useSendFeedbackMutation>;
export type SendFeedbackMutationResult = Apollo.MutationResult<SendFeedbackMutation>;
export type SendFeedbackMutationOptions = Apollo.BaseMutationOptions<SendFeedbackMutation, SendFeedbackMutationVariables>;
export const StripeSceneDocument = gql`
    query StripeScene($id: String!) {
  vehicle(id: $id) {
    id
    licenseExpireDate
  }
}
    `;

/**
 * __useStripeSceneQuery__
 *
 * To run a query within a React component, call `useStripeSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStripeSceneQuery(baseOptions: Apollo.QueryHookOptions<StripeSceneQuery, StripeSceneQueryVariables> & ({ variables: StripeSceneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeSceneQuery, StripeSceneQueryVariables>(StripeSceneDocument, options);
      }
export function useStripeSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeSceneQuery, StripeSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeSceneQuery, StripeSceneQueryVariables>(StripeSceneDocument, options);
        }
export function useStripeSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StripeSceneQuery, StripeSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StripeSceneQuery, StripeSceneQueryVariables>(StripeSceneDocument, options);
        }
export type StripeSceneQueryHookResult = ReturnType<typeof useStripeSceneQuery>;
export type StripeSceneLazyQueryHookResult = ReturnType<typeof useStripeSceneLazyQuery>;
export type StripeSceneSuspenseQueryHookResult = ReturnType<typeof useStripeSceneSuspenseQuery>;
export type StripeSceneQueryResult = Apollo.QueryResult<StripeSceneQuery, StripeSceneQueryVariables>;
export const UpdateFaultAsDoneDocument = gql`
    mutation UpdateFaultAsDone($id: String!) {
  updateFaultAsDone(id: $id) {
    id
  }
}
    `;
export type UpdateFaultAsDoneMutationFn = Apollo.MutationFunction<UpdateFaultAsDoneMutation, UpdateFaultAsDoneMutationVariables>;

/**
 * __useUpdateFaultAsDoneMutation__
 *
 * To run a mutation, you first call `useUpdateFaultAsDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaultAsDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaultAsDoneMutation, { data, loading, error }] = useUpdateFaultAsDoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateFaultAsDoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFaultAsDoneMutation, UpdateFaultAsDoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFaultAsDoneMutation, UpdateFaultAsDoneMutationVariables>(UpdateFaultAsDoneDocument, options);
      }
export type UpdateFaultAsDoneMutationHookResult = ReturnType<typeof useUpdateFaultAsDoneMutation>;
export type UpdateFaultAsDoneMutationResult = Apollo.MutationResult<UpdateFaultAsDoneMutation>;
export type UpdateFaultAsDoneMutationOptions = Apollo.BaseMutationOptions<UpdateFaultAsDoneMutation, UpdateFaultAsDoneMutationVariables>;
export const UseVoucherDocument = gql`
    mutation UseVoucher($vehicleId: String!, $voucher: String!) {
  useVoucher(vehicleId: $vehicleId, voucher: $voucher) {
    id
  }
}
    `;
export type UseVoucherMutationFn = Apollo.MutationFunction<UseVoucherMutation, UseVoucherMutationVariables>;

/**
 * __useUseVoucherMutation__
 *
 * To run a mutation, you first call `useUseVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useVoucherMutation, { data, loading, error }] = useUseVoucherMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      voucher: // value for 'voucher'
 *   },
 * });
 */
export function useUseVoucherMutation(baseOptions?: Apollo.MutationHookOptions<UseVoucherMutation, UseVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseVoucherMutation, UseVoucherMutationVariables>(UseVoucherDocument, options);
      }
export type UseVoucherMutationHookResult = ReturnType<typeof useUseVoucherMutation>;
export type UseVoucherMutationResult = Apollo.MutationResult<UseVoucherMutation>;
export type UseVoucherMutationOptions = Apollo.BaseMutationOptions<UseVoucherMutation, UseVoucherMutationVariables>;
export const VehicleTextOnlyDocument = gql`
    query VehicleTextOnly($id: String!) {
  vehicle(id: $id) {
    VIN
    brand
    commissioning
    mileage
    model
    modelYear
    registerNumber
    runningHours
    type
    warranty
    serviceEvents(orderBy: [{serviceDate: "desc"}]) {
      id
      type
      name
      description
      serviceDate
      mileage
      runningHours
      partner {
        name
      }
    }
  }
}
    `;

/**
 * __useVehicleTextOnlyQuery__
 *
 * To run a query within a React component, call `useVehicleTextOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleTextOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleTextOnlyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleTextOnlyQuery(baseOptions: Apollo.QueryHookOptions<VehicleTextOnlyQuery, VehicleTextOnlyQueryVariables> & ({ variables: VehicleTextOnlyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleTextOnlyQuery, VehicleTextOnlyQueryVariables>(VehicleTextOnlyDocument, options);
      }
export function useVehicleTextOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleTextOnlyQuery, VehicleTextOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleTextOnlyQuery, VehicleTextOnlyQueryVariables>(VehicleTextOnlyDocument, options);
        }
export function useVehicleTextOnlySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VehicleTextOnlyQuery, VehicleTextOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VehicleTextOnlyQuery, VehicleTextOnlyQueryVariables>(VehicleTextOnlyDocument, options);
        }
export type VehicleTextOnlyQueryHookResult = ReturnType<typeof useVehicleTextOnlyQuery>;
export type VehicleTextOnlyLazyQueryHookResult = ReturnType<typeof useVehicleTextOnlyLazyQuery>;
export type VehicleTextOnlySuspenseQueryHookResult = ReturnType<typeof useVehicleTextOnlySuspenseQuery>;
export type VehicleTextOnlyQueryResult = Apollo.QueryResult<VehicleTextOnlyQuery, VehicleTextOnlyQueryVariables>;
export const CreateTransferTokenDocument = gql`
    mutation CreateTransferToken($vehicleId: String!) {
  createTransferToken(vehicleId: $vehicleId)
}
    `;
export type CreateTransferTokenMutationFn = Apollo.MutationFunction<CreateTransferTokenMutation, CreateTransferTokenMutationVariables>;

/**
 * __useCreateTransferTokenMutation__
 *
 * To run a mutation, you first call `useCreateTransferTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransferTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransferTokenMutation, { data, loading, error }] = useCreateTransferTokenMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useCreateTransferTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransferTokenMutation, CreateTransferTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransferTokenMutation, CreateTransferTokenMutationVariables>(CreateTransferTokenDocument, options);
      }
export type CreateTransferTokenMutationHookResult = ReturnType<typeof useCreateTransferTokenMutation>;
export type CreateTransferTokenMutationResult = Apollo.MutationResult<CreateTransferTokenMutation>;
export type CreateTransferTokenMutationOptions = Apollo.BaseMutationOptions<CreateTransferTokenMutation, CreateTransferTokenMutationVariables>;
export const ValidateTransferTokenDocument = gql`
    query ValidateTransferToken($token: String!) {
  validateTransferToken(token: $token) {
    isVehicleOwner
  }
}
    `;

/**
 * __useValidateTransferTokenQuery__
 *
 * To run a query within a React component, call `useValidateTransferTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTransferTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTransferTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateTransferTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateTransferTokenQuery, ValidateTransferTokenQueryVariables> & ({ variables: ValidateTransferTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateTransferTokenQuery, ValidateTransferTokenQueryVariables>(ValidateTransferTokenDocument, options);
      }
export function useValidateTransferTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateTransferTokenQuery, ValidateTransferTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateTransferTokenQuery, ValidateTransferTokenQueryVariables>(ValidateTransferTokenDocument, options);
        }
export function useValidateTransferTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateTransferTokenQuery, ValidateTransferTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateTransferTokenQuery, ValidateTransferTokenQueryVariables>(ValidateTransferTokenDocument, options);
        }
export type ValidateTransferTokenQueryHookResult = ReturnType<typeof useValidateTransferTokenQuery>;
export type ValidateTransferTokenLazyQueryHookResult = ReturnType<typeof useValidateTransferTokenLazyQuery>;
export type ValidateTransferTokenSuspenseQueryHookResult = ReturnType<typeof useValidateTransferTokenSuspenseQuery>;
export type ValidateTransferTokenQueryResult = Apollo.QueryResult<ValidateTransferTokenQuery, ValidateTransferTokenQueryVariables>;
export const TransferVehicleDocument = gql`
    mutation TransferVehicle($token: String!) {
  transferVehicle(token: $token) {
    vehicleId
  }
}
    `;
export type TransferVehicleMutationFn = Apollo.MutationFunction<TransferVehicleMutation, TransferVehicleMutationVariables>;

/**
 * __useTransferVehicleMutation__
 *
 * To run a mutation, you first call `useTransferVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferVehicleMutation, { data, loading, error }] = useTransferVehicleMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTransferVehicleMutation(baseOptions?: Apollo.MutationHookOptions<TransferVehicleMutation, TransferVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferVehicleMutation, TransferVehicleMutationVariables>(TransferVehicleDocument, options);
      }
export type TransferVehicleMutationHookResult = ReturnType<typeof useTransferVehicleMutation>;
export type TransferVehicleMutationResult = Apollo.MutationResult<TransferVehicleMutation>;
export type TransferVehicleMutationOptions = Apollo.BaseMutationOptions<TransferVehicleMutation, TransferVehicleMutationVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    id
    email
    name
    type
    isBusinessAccountOwner
    isConfirmed
    business {
      id
      name
      photo
    }
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export function useWhoAmISuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmISuspenseQueryHookResult = ReturnType<typeof useWhoAmISuspenseQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const HomeSceneDocument = gql`
    query HomeScene {
  vehicles(orderBy: [{updatedAt: "desc"}]) {
    list {
      id
      name
      registerNumber
      VIN
      brand
      model
      photo
      mileage
      runningHours
      licenseExpireDate
      licenseStatus
    }
  }
  reminders(orderBy: [{date: "asc"}], take: 4) {
    totalCount
    list {
      id
      title
      date
      vehicleId
      vehicle {
        id
        name
        registerNumber
        VIN
        brand
        model
      }
    }
  }
}
    `;

/**
 * __useHomeSceneQuery__
 *
 * To run a query within a React component, call `useHomeSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSceneQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeSceneQuery(baseOptions?: Apollo.QueryHookOptions<HomeSceneQuery, HomeSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeSceneQuery, HomeSceneQueryVariables>(HomeSceneDocument, options);
      }
export function useHomeSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeSceneQuery, HomeSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeSceneQuery, HomeSceneQueryVariables>(HomeSceneDocument, options);
        }
export function useHomeSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeSceneQuery, HomeSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeSceneQuery, HomeSceneQueryVariables>(HomeSceneDocument, options);
        }
export type HomeSceneQueryHookResult = ReturnType<typeof useHomeSceneQuery>;
export type HomeSceneLazyQueryHookResult = ReturnType<typeof useHomeSceneLazyQuery>;
export type HomeSceneSuspenseQueryHookResult = ReturnType<typeof useHomeSceneSuspenseQuery>;
export type HomeSceneQueryResult = Apollo.QueryResult<HomeSceneQuery, HomeSceneQueryVariables>;
export const VerifyAcceptInvitationTokenDocument = gql`
    mutation VerifyAcceptInvitationToken($body: VerifyAcceptInvitationDto!) {
  verifyAcceptInvitationToken(body: $body)
}
    `;
export type VerifyAcceptInvitationTokenMutationFn = Apollo.MutationFunction<VerifyAcceptInvitationTokenMutation, VerifyAcceptInvitationTokenMutationVariables>;

/**
 * __useVerifyAcceptInvitationTokenMutation__
 *
 * To run a mutation, you first call `useVerifyAcceptInvitationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAcceptInvitationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAcceptInvitationTokenMutation, { data, loading, error }] = useVerifyAcceptInvitationTokenMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useVerifyAcceptInvitationTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAcceptInvitationTokenMutation, VerifyAcceptInvitationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAcceptInvitationTokenMutation, VerifyAcceptInvitationTokenMutationVariables>(VerifyAcceptInvitationTokenDocument, options);
      }
export type VerifyAcceptInvitationTokenMutationHookResult = ReturnType<typeof useVerifyAcceptInvitationTokenMutation>;
export type VerifyAcceptInvitationTokenMutationResult = Apollo.MutationResult<VerifyAcceptInvitationTokenMutation>;
export type VerifyAcceptInvitationTokenMutationOptions = Apollo.BaseMutationOptions<VerifyAcceptInvitationTokenMutation, VerifyAcceptInvitationTokenMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($body: AcceptInvitationDto!) {
  acceptInvitationToken(body: $body)
}
    `;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const ConfirmDocument = gql`
    mutation Confirm($body: ConfirmDto!) {
  confirm(body: $body) {
    status
  }
}
    `;
export type ConfirmMutationFn = Apollo.MutationFunction<ConfirmMutation, ConfirmMutationVariables>;

/**
 * __useConfirmMutation__
 *
 * To run a mutation, you first call `useConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMutation, { data, loading, error }] = useConfirmMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMutation, ConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmMutation, ConfirmMutationVariables>(ConfirmDocument, options);
      }
export type ConfirmMutationHookResult = ReturnType<typeof useConfirmMutation>;
export type ConfirmMutationResult = Apollo.MutationResult<ConfirmMutation>;
export type ConfirmMutationOptions = Apollo.BaseMutationOptions<ConfirmMutation, ConfirmMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($body: ForgotPasswordDto!) {
  forgotPasswordConsumer(body: $body) {
    status
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($body: LoginDto!) {
  loginClient(body: $body) {
    token
    user {
      id
      name
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterConsumerDocument = gql`
    mutation RegisterConsumer($body: RegisterConsumerUserDto!) {
  registerConsumerUser(body: $body) {
    token
  }
}
    `;
export type RegisterConsumerMutationFn = Apollo.MutationFunction<RegisterConsumerMutation, RegisterConsumerMutationVariables>;

/**
 * __useRegisterConsumerMutation__
 *
 * To run a mutation, you first call `useRegisterConsumerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterConsumerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerConsumerMutation, { data, loading, error }] = useRegisterConsumerMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRegisterConsumerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterConsumerMutation, RegisterConsumerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterConsumerMutation, RegisterConsumerMutationVariables>(RegisterConsumerDocument, options);
      }
export type RegisterConsumerMutationHookResult = ReturnType<typeof useRegisterConsumerMutation>;
export type RegisterConsumerMutationResult = Apollo.MutationResult<RegisterConsumerMutation>;
export type RegisterConsumerMutationOptions = Apollo.BaseMutationOptions<RegisterConsumerMutation, RegisterConsumerMutationVariables>;
export const RegisterBusinessDocument = gql`
    mutation RegisterBusiness($body: RegisterBusinessUserDto!) {
  registerBusinessUser(body: $body) {
    token
  }
}
    `;
export type RegisterBusinessMutationFn = Apollo.MutationFunction<RegisterBusinessMutation, RegisterBusinessMutationVariables>;

/**
 * __useRegisterBusinessMutation__
 *
 * To run a mutation, you first call `useRegisterBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerBusinessMutation, { data, loading, error }] = useRegisterBusinessMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRegisterBusinessMutation(baseOptions?: Apollo.MutationHookOptions<RegisterBusinessMutation, RegisterBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterBusinessMutation, RegisterBusinessMutationVariables>(RegisterBusinessDocument, options);
      }
export type RegisterBusinessMutationHookResult = ReturnType<typeof useRegisterBusinessMutation>;
export type RegisterBusinessMutationResult = Apollo.MutationResult<RegisterBusinessMutation>;
export type RegisterBusinessMutationOptions = Apollo.BaseMutationOptions<RegisterBusinessMutation, RegisterBusinessMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($body: ResetPasswordDto!) {
  resetPassword(body: $body) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ValidateResetTokenDocument = gql`
    query ValidateResetToken($body: ValidateResetTokenDto!) {
  validateResetToken(body: $body) {
    status
  }
}
    `;

/**
 * __useValidateResetTokenQuery__
 *
 * To run a query within a React component, call `useValidateResetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetTokenQuery({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useValidateResetTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables> & ({ variables: ValidateResetTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
      }
export function useValidateResetTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
        }
export function useValidateResetTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
        }
export type ValidateResetTokenQueryHookResult = ReturnType<typeof useValidateResetTokenQuery>;
export type ValidateResetTokenLazyQueryHookResult = ReturnType<typeof useValidateResetTokenLazyQuery>;
export type ValidateResetTokenSuspenseQueryHookResult = ReturnType<typeof useValidateResetTokenSuspenseQuery>;
export type ValidateResetTokenQueryResult = Apollo.QueryResult<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>;
export const FaultIndexSceneDocument = gql`
    query FaultIndexScene($orderBy: [FaultSort!], $skip: Int, $take: Int, $where: FaultWhere) {
  faults(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    totalCount
    list {
      id
      createdAt
      isRead
      vehicle {
        registerNumber
        VIN
        name
        brand
        model
      }
      user {
        name
      }
    }
  }
}
    `;

/**
 * __useFaultIndexSceneQuery__
 *
 * To run a query within a React component, call `useFaultIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaultIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaultIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFaultIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<FaultIndexSceneQuery, FaultIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaultIndexSceneQuery, FaultIndexSceneQueryVariables>(FaultIndexSceneDocument, options);
      }
export function useFaultIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaultIndexSceneQuery, FaultIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaultIndexSceneQuery, FaultIndexSceneQueryVariables>(FaultIndexSceneDocument, options);
        }
export function useFaultIndexSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FaultIndexSceneQuery, FaultIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FaultIndexSceneQuery, FaultIndexSceneQueryVariables>(FaultIndexSceneDocument, options);
        }
export type FaultIndexSceneQueryHookResult = ReturnType<typeof useFaultIndexSceneQuery>;
export type FaultIndexSceneLazyQueryHookResult = ReturnType<typeof useFaultIndexSceneLazyQuery>;
export type FaultIndexSceneSuspenseQueryHookResult = ReturnType<typeof useFaultIndexSceneSuspenseQuery>;
export type FaultIndexSceneQueryResult = Apollo.QueryResult<FaultIndexSceneQuery, FaultIndexSceneQueryVariables>;
export const MarkAsReadDocument = gql`
    mutation MarkAsRead($id: String!) {
  markAsRead(id: $id)
}
    `;
export type MarkAsReadMutationFn = Apollo.MutationFunction<MarkAsReadMutation, MarkAsReadMutationVariables>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsReadMutation, MarkAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(MarkAsReadDocument, options);
      }
export type MarkAsReadMutationHookResult = ReturnType<typeof useMarkAsReadMutation>;
export type MarkAsReadMutationResult = Apollo.MutationResult<MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAsReadMutation, MarkAsReadMutationVariables>;
export const CreateReminderDocument = gql`
    mutation CreateReminder($body: CreateReminderDto!) {
  createReminder(body: $body) {
    id
  }
}
    `;
export type CreateReminderMutationFn = Apollo.MutationFunction<CreateReminderMutation, CreateReminderMutationVariables>;

/**
 * __useCreateReminderMutation__
 *
 * To run a mutation, you first call `useCreateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReminderMutation, { data, loading, error }] = useCreateReminderMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateReminderMutation(baseOptions?: Apollo.MutationHookOptions<CreateReminderMutation, CreateReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReminderMutation, CreateReminderMutationVariables>(CreateReminderDocument, options);
      }
export type CreateReminderMutationHookResult = ReturnType<typeof useCreateReminderMutation>;
export type CreateReminderMutationResult = Apollo.MutationResult<CreateReminderMutation>;
export type CreateReminderMutationOptions = Apollo.BaseMutationOptions<CreateReminderMutation, CreateReminderMutationVariables>;
export const UpdateReminderDocument = gql`
    mutation UpdateReminder($id: String!, $body: UpdateReminderDto!) {
  updateReminder(id: $id, body: $body) {
    id
  }
}
    `;
export type UpdateReminderMutationFn = Apollo.MutationFunction<UpdateReminderMutation, UpdateReminderMutationVariables>;

/**
 * __useUpdateReminderMutation__
 *
 * To run a mutation, you first call `useUpdateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderMutation, { data, loading, error }] = useUpdateReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateReminderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReminderMutation, UpdateReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReminderMutation, UpdateReminderMutationVariables>(UpdateReminderDocument, options);
      }
export type UpdateReminderMutationHookResult = ReturnType<typeof useUpdateReminderMutation>;
export type UpdateReminderMutationResult = Apollo.MutationResult<UpdateReminderMutation>;
export type UpdateReminderMutationOptions = Apollo.BaseMutationOptions<UpdateReminderMutation, UpdateReminderMutationVariables>;
export const DeleteReminderDocument = gql`
    mutation DeleteReminder($id: String!) {
  deleteReminder(id: $id) {
    id
  }
}
    `;
export type DeleteReminderMutationFn = Apollo.MutationFunction<DeleteReminderMutation, DeleteReminderMutationVariables>;

/**
 * __useDeleteReminderMutation__
 *
 * To run a mutation, you first call `useDeleteReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReminderMutation, { data, loading, error }] = useDeleteReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReminderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReminderMutation, DeleteReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReminderMutation, DeleteReminderMutationVariables>(DeleteReminderDocument, options);
      }
export type DeleteReminderMutationHookResult = ReturnType<typeof useDeleteReminderMutation>;
export type DeleteReminderMutationResult = Apollo.MutationResult<DeleteReminderMutation>;
export type DeleteReminderMutationOptions = Apollo.BaseMutationOptions<DeleteReminderMutation, DeleteReminderMutationVariables>;
export const ReminderDocument = gql`
    query Reminder($id: String!) {
  reminder(id: $id) {
    title
    date
    notificationDaysBefore
    isNotificationable
  }
}
    `;

/**
 * __useReminderQuery__
 *
 * To run a query within a React component, call `useReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReminderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReminderQuery(baseOptions: Apollo.QueryHookOptions<ReminderQuery, ReminderQueryVariables> & ({ variables: ReminderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReminderQuery, ReminderQueryVariables>(ReminderDocument, options);
      }
export function useReminderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReminderQuery, ReminderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReminderQuery, ReminderQueryVariables>(ReminderDocument, options);
        }
export function useReminderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReminderQuery, ReminderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReminderQuery, ReminderQueryVariables>(ReminderDocument, options);
        }
export type ReminderQueryHookResult = ReturnType<typeof useReminderQuery>;
export type ReminderLazyQueryHookResult = ReturnType<typeof useReminderLazyQuery>;
export type ReminderSuspenseQueryHookResult = ReturnType<typeof useReminderSuspenseQuery>;
export type ReminderQueryResult = Apollo.QueryResult<ReminderQuery, ReminderQueryVariables>;
export const RemindersDocument = gql`
    query Reminders($orderBy: [ReminderSort!], $skip: Int, $take: Int, $where: ReminderWhere) {
  reminders(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    totalCount
    list {
      id
      title
      date
      vehicleId
      vehicle {
        id
        name
        registerNumber
        VIN
        brand
        model
      }
    }
  }
}
    `;

/**
 * __useRemindersQuery__
 *
 * To run a query within a React component, call `useRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemindersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemindersQuery(baseOptions?: Apollo.QueryHookOptions<RemindersQuery, RemindersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemindersQuery, RemindersQueryVariables>(RemindersDocument, options);
      }
export function useRemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemindersQuery, RemindersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemindersQuery, RemindersQueryVariables>(RemindersDocument, options);
        }
export function useRemindersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RemindersQuery, RemindersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RemindersQuery, RemindersQueryVariables>(RemindersDocument, options);
        }
export type RemindersQueryHookResult = ReturnType<typeof useRemindersQuery>;
export type RemindersLazyQueryHookResult = ReturnType<typeof useRemindersLazyQuery>;
export type RemindersSuspenseQueryHookResult = ReturnType<typeof useRemindersSuspenseQuery>;
export type RemindersQueryResult = Apollo.QueryResult<RemindersQuery, RemindersQueryVariables>;
export const CompleteReminderDocument = gql`
    mutation CompleteReminder($body: CompleteReminderDto!) {
  completeReminder(body: $body) {
    count
    ids
  }
}
    `;
export type CompleteReminderMutationFn = Apollo.MutationFunction<CompleteReminderMutation, CompleteReminderMutationVariables>;

/**
 * __useCompleteReminderMutation__
 *
 * To run a mutation, you first call `useCompleteReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeReminderMutation, { data, loading, error }] = useCompleteReminderMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCompleteReminderMutation(baseOptions?: Apollo.MutationHookOptions<CompleteReminderMutation, CompleteReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteReminderMutation, CompleteReminderMutationVariables>(CompleteReminderDocument, options);
      }
export type CompleteReminderMutationHookResult = ReturnType<typeof useCompleteReminderMutation>;
export type CompleteReminderMutationResult = Apollo.MutationResult<CompleteReminderMutation>;
export type CompleteReminderMutationOptions = Apollo.BaseMutationOptions<CompleteReminderMutation, CompleteReminderMutationVariables>;
export const CreateServiceEventDocument = gql`
    mutation CreateServiceEvent($body: CreateServiceEventDto!) {
  createServiceEvent(body: $body) {
    id
  }
}
    `;
export type CreateServiceEventMutationFn = Apollo.MutationFunction<CreateServiceEventMutation, CreateServiceEventMutationVariables>;

/**
 * __useCreateServiceEventMutation__
 *
 * To run a mutation, you first call `useCreateServiceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceEventMutation, { data, loading, error }] = useCreateServiceEventMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateServiceEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceEventMutation, CreateServiceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceEventMutation, CreateServiceEventMutationVariables>(CreateServiceEventDocument, options);
      }
export type CreateServiceEventMutationHookResult = ReturnType<typeof useCreateServiceEventMutation>;
export type CreateServiceEventMutationResult = Apollo.MutationResult<CreateServiceEventMutation>;
export type CreateServiceEventMutationOptions = Apollo.BaseMutationOptions<CreateServiceEventMutation, CreateServiceEventMutationVariables>;
export const UpdateServiceEventDocument = gql`
    mutation UpdateServiceEvent($id: String!, $body: UpdateServiceEventDto!) {
  updateServiceEvent(id: $id, body: $body) {
    id
  }
}
    `;
export type UpdateServiceEventMutationFn = Apollo.MutationFunction<UpdateServiceEventMutation, UpdateServiceEventMutationVariables>;

/**
 * __useUpdateServiceEventMutation__
 *
 * To run a mutation, you first call `useUpdateServiceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceEventMutation, { data, loading, error }] = useUpdateServiceEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateServiceEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceEventMutation, UpdateServiceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceEventMutation, UpdateServiceEventMutationVariables>(UpdateServiceEventDocument, options);
      }
export type UpdateServiceEventMutationHookResult = ReturnType<typeof useUpdateServiceEventMutation>;
export type UpdateServiceEventMutationResult = Apollo.MutationResult<UpdateServiceEventMutation>;
export type UpdateServiceEventMutationOptions = Apollo.BaseMutationOptions<UpdateServiceEventMutation, UpdateServiceEventMutationVariables>;
export const EditServiceEventDocument = gql`
    query EditServiceEvent($id: String!) {
  serviceEvent(id: $id) {
    type
    name
    photo
    serviceDate
    mileage
    runningHours
    description
  }
}
    `;

/**
 * __useEditServiceEventQuery__
 *
 * To run a query within a React component, call `useEditServiceEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditServiceEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditServiceEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditServiceEventQuery(baseOptions: Apollo.QueryHookOptions<EditServiceEventQuery, EditServiceEventQueryVariables> & ({ variables: EditServiceEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditServiceEventQuery, EditServiceEventQueryVariables>(EditServiceEventDocument, options);
      }
export function useEditServiceEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditServiceEventQuery, EditServiceEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditServiceEventQuery, EditServiceEventQueryVariables>(EditServiceEventDocument, options);
        }
export function useEditServiceEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EditServiceEventQuery, EditServiceEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EditServiceEventQuery, EditServiceEventQueryVariables>(EditServiceEventDocument, options);
        }
export type EditServiceEventQueryHookResult = ReturnType<typeof useEditServiceEventQuery>;
export type EditServiceEventLazyQueryHookResult = ReturnType<typeof useEditServiceEventLazyQuery>;
export type EditServiceEventSuspenseQueryHookResult = ReturnType<typeof useEditServiceEventSuspenseQuery>;
export type EditServiceEventQueryResult = Apollo.QueryResult<EditServiceEventQuery, EditServiceEventQueryVariables>;
export const ServiceEventDocument = gql`
    query ServiceEvent($id: String!) {
  serviceEvent(id: $id) {
    type
    name
    serviceDate
    mileage
    photo
    runningHours
    description
    partner {
      name
      photo
    }
  }
}
    `;

/**
 * __useServiceEventQuery__
 *
 * To run a query within a React component, call `useServiceEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceEventQuery(baseOptions: Apollo.QueryHookOptions<ServiceEventQuery, ServiceEventQueryVariables> & ({ variables: ServiceEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceEventQuery, ServiceEventQueryVariables>(ServiceEventDocument, options);
      }
export function useServiceEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceEventQuery, ServiceEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceEventQuery, ServiceEventQueryVariables>(ServiceEventDocument, options);
        }
export function useServiceEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ServiceEventQuery, ServiceEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServiceEventQuery, ServiceEventQueryVariables>(ServiceEventDocument, options);
        }
export type ServiceEventQueryHookResult = ReturnType<typeof useServiceEventQuery>;
export type ServiceEventLazyQueryHookResult = ReturnType<typeof useServiceEventLazyQuery>;
export type ServiceEventSuspenseQueryHookResult = ReturnType<typeof useServiceEventSuspenseQuery>;
export type ServiceEventQueryResult = Apollo.QueryResult<ServiceEventQuery, ServiceEventQueryVariables>;
export const UpdateBusinessDocument = gql`
    mutation UpdateBusiness($body: UpdateBusinessDto!) {
  updateBusiness(body: $body) {
    id
  }
}
    `;
export type UpdateBusinessMutationFn = Apollo.MutationFunction<UpdateBusinessMutation, UpdateBusinessMutationVariables>;

/**
 * __useUpdateBusinessMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessMutation, { data, loading, error }] = useUpdateBusinessMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateBusinessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessMutation, UpdateBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessMutation, UpdateBusinessMutationVariables>(UpdateBusinessDocument, options);
      }
export type UpdateBusinessMutationHookResult = ReturnType<typeof useUpdateBusinessMutation>;
export type UpdateBusinessMutationResult = Apollo.MutationResult<UpdateBusinessMutation>;
export type UpdateBusinessMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessMutation, UpdateBusinessMutationVariables>;
export const BusinessDocument = gql`
    query Business {
  business {
    name
    photo
  }
}
    `;

/**
 * __useBusinessQuery__
 *
 * To run a query within a React component, call `useBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessQuery(baseOptions?: Apollo.QueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
      }
export function useBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
        }
export function useBusinessSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
        }
export type BusinessQueryHookResult = ReturnType<typeof useBusinessQuery>;
export type BusinessLazyQueryHookResult = ReturnType<typeof useBusinessLazyQuery>;
export type BusinessSuspenseQueryHookResult = ReturnType<typeof useBusinessSuspenseQuery>;
export type BusinessQueryResult = Apollo.QueryResult<BusinessQuery, BusinessQueryVariables>;
export const ChangeUserInformationDocument = gql`
    mutation ChangeUserInformation($body: ChangeUserInformationDto!) {
  changeUserInformation(body: $body) {
    id
  }
}
    `;
export type ChangeUserInformationMutationFn = Apollo.MutationFunction<ChangeUserInformationMutation, ChangeUserInformationMutationVariables>;

/**
 * __useChangeUserInformationMutation__
 *
 * To run a mutation, you first call `useChangeUserInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserInformationMutation, { data, loading, error }] = useChangeUserInformationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useChangeUserInformationMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserInformationMutation, ChangeUserInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserInformationMutation, ChangeUserInformationMutationVariables>(ChangeUserInformationDocument, options);
      }
export type ChangeUserInformationMutationHookResult = ReturnType<typeof useChangeUserInformationMutation>;
export type ChangeUserInformationMutationResult = Apollo.MutationResult<ChangeUserInformationMutation>;
export type ChangeUserInformationMutationOptions = Apollo.BaseMutationOptions<ChangeUserInformationMutation, ChangeUserInformationMutationVariables>;
export const UserEditWhoAmIDocument = gql`
    query UserEditWhoAmI {
  whoAmI {
    email
    name
  }
}
    `;

/**
 * __useUserEditWhoAmIQuery__
 *
 * To run a query within a React component, call `useUserEditWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEditWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>(UserEditWhoAmIDocument, options);
      }
export function useUserEditWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>(UserEditWhoAmIDocument, options);
        }
export function useUserEditWhoAmISuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>(UserEditWhoAmIDocument, options);
        }
export type UserEditWhoAmIQueryHookResult = ReturnType<typeof useUserEditWhoAmIQuery>;
export type UserEditWhoAmILazyQueryHookResult = ReturnType<typeof useUserEditWhoAmILazyQuery>;
export type UserEditWhoAmISuspenseQueryHookResult = ReturnType<typeof useUserEditWhoAmISuspenseQuery>;
export type UserEditWhoAmIQueryResult = Apollo.QueryResult<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>;
export const TeamCreateSceneDocument = gql`
    mutation TeamCreateScene($body: InviteBusinessUserDto!) {
  inviteBusinessUser(body: $body) {
    id
  }
}
    `;
export type TeamCreateSceneMutationFn = Apollo.MutationFunction<TeamCreateSceneMutation, TeamCreateSceneMutationVariables>;

/**
 * __useTeamCreateSceneMutation__
 *
 * To run a mutation, you first call `useTeamCreateSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamCreateSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamCreateSceneMutation, { data, loading, error }] = useTeamCreateSceneMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useTeamCreateSceneMutation(baseOptions?: Apollo.MutationHookOptions<TeamCreateSceneMutation, TeamCreateSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamCreateSceneMutation, TeamCreateSceneMutationVariables>(TeamCreateSceneDocument, options);
      }
export type TeamCreateSceneMutationHookResult = ReturnType<typeof useTeamCreateSceneMutation>;
export type TeamCreateSceneMutationResult = Apollo.MutationResult<TeamCreateSceneMutation>;
export type TeamCreateSceneMutationOptions = Apollo.BaseMutationOptions<TeamCreateSceneMutation, TeamCreateSceneMutationVariables>;
export const TeamIndexSceneDocument = gql`
    query TeamIndexScene {
  users {
    list {
      id
      name
      email
      isBusinessAccountOwner
      isEmployee
    }
    totalCount
  }
}
    `;

/**
 * __useTeamIndexSceneQuery__
 *
 * To run a query within a React component, call `useTeamIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamIndexSceneQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>(TeamIndexSceneDocument, options);
      }
export function useTeamIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>(TeamIndexSceneDocument, options);
        }
export function useTeamIndexSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>(TeamIndexSceneDocument, options);
        }
export type TeamIndexSceneQueryHookResult = ReturnType<typeof useTeamIndexSceneQuery>;
export type TeamIndexSceneLazyQueryHookResult = ReturnType<typeof useTeamIndexSceneLazyQuery>;
export type TeamIndexSceneSuspenseQueryHookResult = ReturnType<typeof useTeamIndexSceneSuspenseQuery>;
export type TeamIndexSceneQueryResult = Apollo.QueryResult<TeamIndexSceneQuery, TeamIndexSceneQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const CreateVehicleDocument = gql`
    mutation CreateVehicle($body: CreateVehicleDto!) {
  createVehicle(body: $body) {
    id
  }
}
    `;
export type CreateVehicleMutationFn = Apollo.MutationFunction<CreateVehicleMutation, CreateVehicleMutationVariables>;

/**
 * __useCreateVehicleMutation__
 *
 * To run a mutation, you first call `useCreateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleMutation, { data, loading, error }] = useCreateVehicleMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<CreateVehicleMutation, CreateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVehicleMutation, CreateVehicleMutationVariables>(CreateVehicleDocument, options);
      }
export type CreateVehicleMutationHookResult = ReturnType<typeof useCreateVehicleMutation>;
export type CreateVehicleMutationResult = Apollo.MutationResult<CreateVehicleMutation>;
export type CreateVehicleMutationOptions = Apollo.BaseMutationOptions<CreateVehicleMutation, CreateVehicleMutationVariables>;
export const UpdateVehicleDocument = gql`
    mutation UpdateVehicle($id: String!, $body: UpdateVehicleDto!) {
  updateVehicle(id: $id, body: $body) {
    id
  }
}
    `;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<UpdateVehicleMutation, UpdateVehicleMutationVariables>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, options);
      }
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>;
export type UpdateVehicleMutationResult = Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export const DeleteVehicleDocument = gql`
    mutation DeleteVehicle($id: String!) {
  deleteVehicle(id: $id) {
    id
  }
}
    `;
export type DeleteVehicleMutationFn = Apollo.MutationFunction<DeleteVehicleMutation, DeleteVehicleMutationVariables>;

/**
 * __useDeleteVehicleMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleMutation, { data, loading, error }] = useDeleteVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVehicleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVehicleMutation, DeleteVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVehicleMutation, DeleteVehicleMutationVariables>(DeleteVehicleDocument, options);
      }
export type DeleteVehicleMutationHookResult = ReturnType<typeof useDeleteVehicleMutation>;
export type DeleteVehicleMutationResult = Apollo.MutationResult<DeleteVehicleMutation>;
export type DeleteVehicleMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleMutation, DeleteVehicleMutationVariables>;
export const VehicleDocument = gql`
    query Vehicle($id: String!) {
  vehicle(id: $id) {
    name
    type
    registerNumber
    VIN
    mileage
    modelYear
    commissioning
    warranty
    runningHours
    model
    photo
    modelYear
    brand
    description
  }
}
    `;

/**
 * __useVehicleQuery__
 *
 * To run a query within a React component, call `useVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleQuery(baseOptions: Apollo.QueryHookOptions<VehicleQuery, VehicleQueryVariables> & ({ variables: VehicleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, options);
      }
export function useVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, options);
        }
export function useVehicleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, options);
        }
export type VehicleQueryHookResult = ReturnType<typeof useVehicleQuery>;
export type VehicleLazyQueryHookResult = ReturnType<typeof useVehicleLazyQuery>;
export type VehicleSuspenseQueryHookResult = ReturnType<typeof useVehicleSuspenseQuery>;
export type VehicleQueryResult = Apollo.QueryResult<VehicleQuery, VehicleQueryVariables>;
export const VehicleIndexSceneDocument = gql`
    query VehicleIndexScene($orderBy: [VehicleSort!], $skip: Int, $take: Int, $where: VehicleWhere) {
  vehicles(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    totalCount
    list {
      id
      name
      registerNumber
      model
      brand
      licenseExpireDate
      licenseStatus
      VIN
    }
  }
}
    `;

/**
 * __useVehicleIndexSceneQuery__
 *
 * To run a query within a React component, call `useVehicleIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVehicleIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>(VehicleIndexSceneDocument, options);
      }
export function useVehicleIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>(VehicleIndexSceneDocument, options);
        }
export function useVehicleIndexSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>(VehicleIndexSceneDocument, options);
        }
export type VehicleIndexSceneQueryHookResult = ReturnType<typeof useVehicleIndexSceneQuery>;
export type VehicleIndexSceneLazyQueryHookResult = ReturnType<typeof useVehicleIndexSceneLazyQuery>;
export type VehicleIndexSceneSuspenseQueryHookResult = ReturnType<typeof useVehicleIndexSceneSuspenseQuery>;
export type VehicleIndexSceneQueryResult = Apollo.QueryResult<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>;
export const VehicleShowDocument = gql`
    query VehicleShow($id: String!) {
  vehicle(id: $id) {
    id
    token
    name
    type
    registerNumber
    VIN
    mileage
    modelYear
    commissioning
    warranty
    runningHours
    hasActiveLicense
    model
    modelYear
    licenseExpireDate
    licenseStatus
    brand
    photo
    description
    photo
    reminders(orderBy: [{date: "asc"}, {title: "asc"}]) {
      id
      title
      date
    }
    serviceEvents(orderBy: [{serviceDate: "desc"}]) {
      id
      name
      serviceDate
      type
      description
      isLocked
      partner {
        photo
      }
    }
  }
}
    `;

/**
 * __useVehicleShowQuery__
 *
 * To run a query within a React component, call `useVehicleShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleShowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleShowQuery(baseOptions: Apollo.QueryHookOptions<VehicleShowQuery, VehicleShowQueryVariables> & ({ variables: VehicleShowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleShowQuery, VehicleShowQueryVariables>(VehicleShowDocument, options);
      }
export function useVehicleShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleShowQuery, VehicleShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleShowQuery, VehicleShowQueryVariables>(VehicleShowDocument, options);
        }
export function useVehicleShowSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VehicleShowQuery, VehicleShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VehicleShowQuery, VehicleShowQueryVariables>(VehicleShowDocument, options);
        }
export type VehicleShowQueryHookResult = ReturnType<typeof useVehicleShowQuery>;
export type VehicleShowLazyQueryHookResult = ReturnType<typeof useVehicleShowLazyQuery>;
export type VehicleShowSuspenseQueryHookResult = ReturnType<typeof useVehicleShowSuspenseQuery>;
export type VehicleShowQueryResult = Apollo.QueryResult<VehicleShowQuery, VehicleShowQueryVariables>;