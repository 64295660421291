import moment from 'moment';
import { Link } from 'react-router-dom';
import { VehicleName } from 'shared-ui/lib/components/VehicleName';
import { TableColumn } from 'shared-ui/lib/customs/react/generic-table/TableColumn';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { routesConfig } from '../config/routes.config';
import { RemindersQuery } from '../generated/graphql';

const id: TableColumn = {
  field: 'id',
  text: 'Reminder:id',
};

const date: TableColumn = {
  field: 'date',
  text: 'Reminder:date',
  formatter: (data: RemindersQuery['reminders']['list'][0]) => {
    const isLate = moment().isSameOrAfter(moment(data.date));
    return (
      <div className={`${isLate ? 'text-red-color' : 'text-gray-color'}`}>
        {formatDate(data.date)}
      </div>
    );
  },
};

const title: TableColumn = {
  field: 'title',
  text: 'Reminder:reminder',
};

const vehicle: TableColumn = {
  field: 'vehicle.name',
  text: 'Reminder:vehicle',
  formatter: (data: RemindersQuery['reminders']['list'][0]) => (
    <VehicleName vehicle={data.vehicle} />
  ),
};

const registerNumber: TableColumn = {
  field: 'vehicle.registerNumber',
  text: 'Reminder:VIN',
};

const linkToVehicle: TableColumn = {
  field: 'vehicleId',
  text: 'General:link',
  formatter: (data: RemindersQuery['reminders']['list'][0]) => (
    <Link
      to={routesConfig.VEHICLES.SHOW.replace(':id', data.vehicleId)}
      className="hover:text-primary text-gray-color"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </Link>
  ),
};

export const reminderColumns = {
  title,
  date,
  vehicle,
  registerNumber,
  linkToVehicle,
  id,
};
