import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { serviceEventInputs } from '../../inputs/service-event.inputs';

const form: FormInput[] = [
  serviceEventInputs.type,
  serviceEventInputs.name,
  serviceEventInputs.photo,
  serviceEventInputs.serviceDate,
  serviceEventInputs.mileage,
  serviceEventInputs.runningHours,
  serviceEventInputs.description,
];

export const serviceEventForm = createForm(form);

export type ServiceEventFormBody = {
  type: string;
  name: string;
  serviceDate: string;
  photo: null | File | string;
  mileage: number;
  runningHours: number;
  description: string;
};
