import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { reminderInputs } from '../../inputs/reminder.inputs';

const form: FormInput[] = [
  reminderInputs.title,
  reminderInputs.date,
  reminderInputs.isNotificationable,
  reminderInputs.notificationDaysBefore,
];

export const reminderForm = createForm(form);

export type ReminderFormBody = {
  title: string;
  date: string;
  isNotificationable: boolean;
  notificationDaysBefore: number;
};
