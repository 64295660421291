import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const title: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'title',
    type: 'text',
    label: 'Reminder:title',
  },
};

const date: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'date',
    type: 'date',
    label: 'Reminder:date',
  },
};

const notificationDaysBefore: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.number()
    .when('isNotificationable', {
      is: true,
      then: (schema) => schema.min(1).max(356).required(),
      otherwise: (schema) => schema.transform(() => null),
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .nullable() as any,
  props: {
    name: 'notificationDaysBefore',
    type: 'text',
    label: 'Reminder:notificationDaysBefore',
  },
};

const isNotificationable: FormInput = {
  type: 'checkbox',
  initValue: false,
  validation: Yup.bool().required(),
  props: {
    name: 'isNotificationable',
    label: 'Reminder:isNotificationable',
  },
};

export const reminderInputs = {
  title,
  date,
  notificationDaysBefore,
  isNotificationable,
};
