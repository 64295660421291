import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { userInputs } from '../../inputs/user.inputs';

const form: FormInput[] = [userInputs.email];

export const forgotPasswordForm = createForm(form);

export type ForgotPasswordFormBody = {
  email: string;
};
