import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const message: FormInput = {
  type: 'textarea',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'message',
    label: 'Feedback:message',
  },
};

export const feedbackInput = {
  message,
};
